import { useState, useEffect } from 'react';
import axios from 'axios';
import firebaseService from '../../../services/firebase.service';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { Loader, Placeholder } from 'rsuite';
import dayjs from 'dayjs';
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export default function MessagesTable(props) {
	const {
		search = '',
		filter = {},
		title = 'Messages',
		messagingServiceSid,
		dateRange,
		broadcastId
	} = props;

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(20);
	const [sort, setSort] = useState('createdAt');
	const [sortDirection, setSortDirection] = useState('desc');

	if (messagingServiceSid) filter.messagingServiceSid = messagingServiceSid;
	if (broadcastId) filter.broadcastId = broadcastId;
	if (dateRange)
		filter.createdAt = {
			$gte: dateRange?.startDate,
			$lt: dateRange?.endDate
		};

	const columns = [
		{
			name: 'Date',
			selector: (row) => row.sendAt,
			sortable: true,
			sortField: 'sendAt',
			filterable: true,
			format: (row) => {
				return dayjs(row.sendAt).format('LLL');
			}
		},
		{
			name: 'To',
			selector: (row) => row.to,
			format: (row) => {
				let detail = row.crmContactFirstName
					? `${row.crmContactFirstName} ${row.crmContactLastName} `
					: '';
				return detail.toUpperCase() + '(' + row.to + ')';
			}
		},
		{
			name: 'From',
			selector: (row) => row.messagingServiceName
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			omit: true
		},
		{
			name: 'Message',
			selector: (row) => row.body,
			format: (row) => {
				return (
					row.body.substring(0, 60) +
					(row.body.length > 60 ? '...' : '')
				);
			}
		}
	];

	const fetchMessages = async (page) => {
		setLoading(true);

		const token = await firebaseService.auth.currentUser.getIdToken(true);
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/messages`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					page,
					perPage,
					sort,
					sortDirection,
					search,
					filter
				}
			}
		);

		setData(response.data.data);
		setTotalRows(response.data.total);
		setLoading(false);
	};

	const handlePageChange = (page) => {
		fetchMessages(page);
	};

	const handleSort = async (column, sortDirection) => {
		setLoading(true);

		const token = await firebaseService.auth.currentUser.getIdToken(true);

		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/messages`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					page: 1,
					perPage,
					sort: column.sortField,
					sortDirection,
					search,
					filter
				}
			}
		);

		setData(response.data.data);
		setLoading(false);
		setSort(column.sortField);
		setSortDirection(sortDirection);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

		const token = await firebaseService.auth.currentUser.getIdToken(true);

		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/messages`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					page,
					perPage: newPerPage,
					sort,
					sortDirection,
					search,
					filter
				}
			}
		);

		setData(response.data.data);
		setPerPage(newPerPage);
		setLoading(false);
	};

	const ExpandedComponent = (data) => {
		return (
			<div className="message-container">
				<div className="message-header">
					<div>
						<strong>From: </strong>{' '}
						{data.data.from
							? data.data.from
							: data.data.messagingServiceName}
					</div>
					<div>
						<strong>To: </strong> {data.data.to}
					</div>
					<div>
						<strong>Status: </strong> {data.data.status}
					</div>
				</div>
				<div
					className="message-body"
					dangerouslySetInnerHTML={{
						__html: data.data.body.replace(
							/(?:\r\n|\r|\n)/g,
							'<br />'
						)
					}}
				></div>
			</div>
		);
	};

	const conditionalRowStyles = [
		{
			when: (row) =>
				['requested', 'accepted', 'queued', 'sending', 'sent'].indexOf(
					row.status
				) > -1,
			style: {
				backgroundColor: '#cfe2ff'
			}
		},
		{
			when: (row) => ['delivered'].indexOf(row.status) > -1,
			style: {
				backgroundColor: '#d1e7dd'
			}
		},
		{
			when: (row) => ['received'].indexOf(row.status) > -1,
			style: {
				backgroundColor: '#fff3cd'
			}
		},
		{
			when: (row) =>
				[
					'requested',
					'accepted',
					'queued',
					'sending',
					'sent',
					'received',
					'delivered',
					'scheduled'
				].indexOf(row.status) === -1,
			style: {
				backgroundColor: '#f8d7da'
			}
		}
	];

	useEffect(() => {
		fetchMessages(1); // fetch page 1 of users
	}, [search, dateRange, messagingServiceSid, broadcastId]);

	return (
		<DataTable
			title={title}
			columns={columns}
			data={data}
			progressPending={loading}
			progressComponent={
				<div>
					<Placeholder.Paragraph rows={8} />
					<Loader backdrop content="Hang on..." vertical />
				</div>
			}
			paginationPerPage={perPage}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			defaultSortFieldId={1}
			defaultSortAsc={false}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			onSort={handleSort}
			sortServer
			fixedHeader
			expandableRows
			expandableRowsComponent={ExpandedComponent}
			conditionalRowStyles={conditionalRowStyles}
		/>
	);
}
