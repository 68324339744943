import { useState } from 'react';

import DatePicker from '../../datepicker/datepicker.component';
import ChartMessagesDaily from '../reports/charts/messages_daily';
import ChartMessagesHourly from '../reports/charts/messages_hourly';
import ChartMessagesStatus from '../reports/charts/messages_status';

import MessagingServicePicker from '../../messaging_service_picker/messaging_service_picker.component';
import dayjs from 'dayjs';

export default function MessagesReport(props) {
	const [messagingService, setMessagingService] = useState();
	const [dateRange, setDateRange] = useState({
		startDate: dayjs().subtract(6, 'days').toDate(),
		endDate: dayjs().toDate()
	});

	return (
		<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2">Messaging Reports</h1>
				<div className="btn-toolbar mb-2 mb-md-0">
					<div className="btn-group me-2">
						<MessagingServicePicker
							defaultService={messagingService}
							setService={setMessagingService}
						/>
					</div>
					<DatePicker
						dateRange={dateRange}
						setDateRange={setDateRange}
					/>
				</div>
			</div>
			<div className="container">
				<div className="row mb-4">
					<div className="col">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">
									Sent and Received (Hourly)
								</h5>
								<ChartMessagesHourly
									dateRange={dateRange}
									messagingService={messagingService}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="card">
							<div className="card-body">
								<h5 className="card-title">
									Sent and Received (Daily)
								</h5>
								<ChartMessagesDaily
									dateRange={dateRange}
									messagingService={messagingService}
								/>
							</div>
						</div>
					</div>
					<div className="col">
						<ChartMessagesStatus
							dateRange={dateRange}
							messagingService={messagingService}
						/>
					</div>
				</div>
			</div>
		</main>
	);
}
