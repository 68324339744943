import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import firebaseService from '../../services/firebase.service';

const feather = require('feather-icons');

export default function SidebarComponent() {
	const logUserOut = async () => {
		await firebaseService.auth.signOut();
	};

	useEffect(() => {
		feather.replace({ 'aria-hidden': 'true' });
	});

	return (
		<nav
			id="sidebarMenu"
			className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
		>
			<div className="position-sticky pt-3 sidebar-sticky">
				<ul className="nav flex-column">
					<li className="nav-item">
						<Link to="/" className="nav-link active">
							<span
								data-feather="home"
								className="align-text-bottom"
							></span>
							Dashboard
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/broadcasts" className="nav-link active">
							<span
								data-feather="layers"
								className="align-text-bottom"
							></span>
							Broadcasts
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/messages" className="nav-link active">
							<span
								data-feather="layers"
								className="align-text-bottom"
							></span>
							Messages
						</Link>
					</li>
				</ul>

				<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
					<span>Saved reports</span>
					<a
						className="link-secondary"
						href="#"
						aria-label="Add a new report"
					>
						<span
							data-feather="plus-circle"
							className="align-text-bottom"
						></span>
					</a>
				</h6>
				<ul className="nav flex-column mb-2">
					<li className="nav-item">
						<Link to="/stats/messages" className="nav-link">
							<span
								data-feather="file-text"
								className="align-text-bottom"
							></span>
							Daily messages
						</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
}
