import { useState, useEffect } from 'react';

import axios from 'axios';
import firebaseService from '../../../../services/firebase.service';
import {
	BarChart,
	Bar,
	Cell,
	PieChart,
	Pie,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

import dayjs from 'dayjs';
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export default function ChartBroadcastStatus(props) {
	const [data, setData] = useState([]);
	const { broadcastId } = props;

	const COLORS = ['#0088FE', '#CC0000', '#FFBB28', '#FF8042'];

	const getData = async () => {
		if (broadcastId) {
			const token = await firebaseService.auth.currentUser.getIdToken(
				true
			);

			const resp = await axios.get(
				`${process.env.REACT_APP_SERVER}/api/broadcasts/${broadcastId}`,
				{
					headers: {
						authorization: `Bearer ${token}`
					}
				}
			);

			if (resp.status !== 200) {
				return {};
			}

			setData([
				{
					name: 'Delivered',
					value: resp.data.job.numDelivered
				},
				{
					name: 'Failed',
					value: resp.data.job.numFailed
				},
				{
					name: 'Removed',
					value: resp.data.job.numRemoved
				}
			]);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className="card">
			<div className="card-body">
				<h5 className="card-title">
					Optout rate for this broadcast was{' '}
					{data.length > 0
						? Math.round(
								(data[2]?.value /
									(data[0]?.value + data[1]?.value)) *
									100
						  )
						: ''}
					%.
				</h5>

				<div className="my-4 w-100 chart-parent-small">
					<ResponsiveContainer width="100%">
						<PieChart width={800} height={400}>
							<Legend verticalAlign="bottom" height={36} />
							<Pie
								label
								data={data}
								dataKey="value"
								innerRadius={60}
								outerRadius={80}
								fill="#8884d8"
								paddingAngle={5}
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
}
