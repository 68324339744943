import { useState, useEffect } from 'react';

import axios from 'axios';
import firebaseService from '../../../../services/firebase.service';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

import dayjs from 'dayjs';
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export default function ChartMessagesHourly(props) {
	const { dateRange, messagingService } = props;
	const [data, setData] = useState([]);

	console.log(props);

	const getData = async () => {
		const token = await firebaseService.auth.currentUser.getIdToken(true);
		const resp = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/stats/messages/hourly`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					dateStart: dateRange.startDate,
					dateFinish: dateRange.endDate,
					messagingServiceSid: messagingService
				}
			}
		);

		const data = resp.data.map((d) => {
			return {
				date: d.data.date,
				in: d.data.num_in,
				out: d.data.num_out,
				total: d.data.num_total
			};
		});

		const data_reduced = [
			...data
				.reduce((r, o) => {
					const key = o.date;

					const item =
						r.get(key) ||
						Object.assign({}, o, {
							in: 0,
							out: 0,
							total: 0
						});

					item.in += o.in;
					item.out += o.out;
					item.total += o.total;

					return r.set(key, item);
				}, new Map())
				.values()
		];

		const sorted_data = data_reduced.sort(
			(a, b) => new Date(a.date) - new Date(b.date)
		);

		setData(sorted_data);
	};

	const formatXLabel = (d) => {
		return dayjs(d.date).format('ha');
	};

	useEffect(() => {
		getData();
	}, [dateRange, messagingService]);

	return (
		<div className="my-4 w-100 chart-parent-small">
			<ResponsiveContainer width="100%">
				<BarChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey={formatXLabel} />
					<YAxis />
					<Tooltip />
					<Bar dataKey="in" stackId="a" fill="#0A2463" />
					<Bar dataKey="out" stackId="a" fill="#3E92CC" />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}
