import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderComponent from './header.component';
import SideBarComponent from './sidebar.component';
import MessagesPage from '../pages/messages/messages.page';
import MessagesReport from '../pages/reports/messages_report';
import BroadcastPage from '../pages/broadcasts/broadcasts.page.component.jsx';
import BroadcastView from '../pages/broadcasts/broadcast.component';
import SignInPage from '../pages/signin.page';
import { useState } from 'react';
import BroadcastNew from '../pages/broadcasts/broadcast_new.component';

export default function Dash(props) {
	const { authorized } = props;
	const [search = '', setSearch] = useState('');

	const changeSearch = (searchValue) => {
		setSearch(searchValue);
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<Router>
					<HeaderComponent
						authorized={authorized}
						changeSearch={changeSearch}
					/>
					<div className="container-fluid">
						<div className="row">
							<SideBarComponent />
							<Routes>
								{authorized ? (
									<>
										<Route
											path="/"
											element={<MessagesReport />}
										/>

										<Route
											path="/messages"
											element={
												<MessagesPage search={search} />
											}
										/>

										<Route
											path="/broadcasts"
											element={
												<BroadcastPage
													search={search}
												/>
											}
										/>

										<Route
											path="/broadcasts/new"
											element={<BroadcastNew />}
										/>

										<Route
											path="/broadcasts/:id"
											element={<BroadcastView />}
										/>

										<Route
											path="/stats/messages"
											element={<MessagesReport />}
										/>
									</>
								) : (
									<Route
										path="/signin"
										element={<SignInPage />}
									/>
								)}
								<Route
									path="*"
									element={
										<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
											<p>Not found.</p>
										</main>
									}
								/>
							</Routes>
						</div>
					</div>
				</Router>
			</div>
		</div>
	);
}
