import React, { useState, useRef } from 'react';
import {
	Form,
	ButtonToolbar,
	Button,
	Input,
	DatePicker,
	Schema,
	Divider
} from 'rsuite';
import dayjs from 'dayjs';
import HubspotListPicker from '../../hubspot_list_picker/hubspot_list_picker.component';
import MessagingServicePicker from '../../messaging_service_picker/messaging_service_picker.component';
import firebaseService from '../../../services/firebase.service';
import axios from 'axios';

const Textarea = React.forwardRef((props, ref) => (
	<Input {...props} as="textarea" ref={ref} />
));

export default function BroadcastForm(props) {
	const { broadcast, formDone, formCancel } = props;
	const formRef = useRef();

	const defaultForm =
		typeof broadcast === 'object'
			? { ...broadcast, sendAt: new Date(broadcast.sendAt) }
			: { sendAt: dayjs().add(1, 'hour').startOf('hour').toDate() };

	const [formValue, setFormValue] = useState(defaultForm);

	const model = Schema.Model({
		name: Schema.Types.StringType().isRequired('This field is required.'),
		messagingServiceId: Schema.Types.StringType().isRequired(
			'Please select the sender service'
		),
		// sendAt: Schema.Types.StringType().isRequired('Send time is required.'),
		body: Schema.Types.StringType().isRequired(
			'You cannot send a blank message.'
		),
		hubspotListId: Schema.Types.StringType().isRequired(
			'Please select the sender list'
		)
	});

	const handleSubmit = async (e, form) => {
		if (!formRef.current.check()) {
			return;
		}

		if (broadcast?._id) {
			const token = await firebaseService.auth.currentUser.getIdToken(
				true
			);
			const resp = await axios.patch(
				`${process.env.REACT_APP_SERVER}/api/broadcasts/${broadcast._id}`,
				formValue,
				{
					headers: {
						authorization: `Bearer ${token}`
					}
				}
			);

			formDone(resp.data._id);
		} else {
			const token = await firebaseService.auth.currentUser.getIdToken(
				true
			);
			const resp = await axios.post(
				`${process.env.REACT_APP_SERVER}/api/broadcasts`,
				formValue,
				{
					headers: {
						authorization: `Bearer ${token}`
					}
				}
			);

			formDone(resp.data._id);
		}
	};

	return (
		<Form
			ref={formRef}
			model={model}
			formDefaultValue={formValue}
			onChange={(newFormValue) => {
				setFormValue({
					...formValue,
					...newFormValue,
					sendAt: new Date(newFormValue.sendAt)
				});
			}}
			onSubmit={handleSubmit}
		>
			<div className="row">
				<div className="col">
					<Form.Group controlId="name">
						<Form.ControlLabel>Broadcast Name</Form.ControlLabel>
						<Form.Control
							name="name"
							placeholder="eg. Weekend Promotion"
						/>
					</Form.Group>

					<Form.Group controlId="hubspotListId">
						<Form.ControlLabel>Send to:</Form.ControlLabel>
						<Form.Control
							name="hubspotListId"
							accepter={HubspotListPicker}
							defaultValue={formValue.hubspotListId}
						/>
					</Form.Group>

					<Form.Group controlId="messagingServiceId">
						<Form.ControlLabel>Send from:</Form.ControlLabel>
						<Form.Control
							name="messagingServiceId"
							accepter={MessagingServicePicker}
							defaultValue={formValue.messagingServiceId}
						/>
					</Form.Group>

					<Form.Group controlId="sendAt">
						<Form.ControlLabel>Send at:</Form.ControlLabel>
						<Form.Control
							name="sendAt"
							accepter={DatePicker}
							format="yyyy-MM-dd h:mm a"
							value={formValue.sendAt}
							cleanable={false}
						/>
						<Form.HelpText>
							Detected timezone:{' '}
							{Intl.DateTimeFormat().resolvedOptions().timeZone}
						</Form.HelpText>
					</Form.Group>
				</div>
				<div className="col">
					<Form.Group controlId="body">
						<Form.ControlLabel>Message Body</Form.ControlLabel>
						<Form.Control
							rows={14}
							name="body"
							accepter={Textarea}
						/>
					</Form.Group>
				</div>
				<div className="col">
					<Form.Group controlId="body">
						<Form.ControlLabel>URL Shortener</Form.ControlLabel>
						<p>
							Any URLs used in the message will be shortened to
							something like https://go.alexell.io/12345
						</p>
					</Form.Group>
					<Form.Group controlId="body">
						<Form.ControlLabel>Unsubscribe Block</Form.ControlLabel>
						<p>
							An optout message WILL NOT be automatically applied.
							If this is a marketing message, please make sure you
							append something along the lines of{' '}
							<em>Reply STOP to Optout.</em>
						</p>
					</Form.Group>
					<Form.Group controlId="body">
						<Form.ControlLabel>
							Available Merge Fields
						</Form.ControlLabel>
						<span className="badge rounded-pill bg-secondary">
							[firstname]
						</span>
						<span className="badge rounded-pill bg-secondary">
							[lastname]
						</span>
					</Form.Group>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<Divider />
					<Form.Group>
						<ButtonToolbar>
							<Button appearance="primary" type="submit">
								Save Broadcast
							</Button>
							<Button appearance="default" onClick={formDone}>
								Cancel
							</Button>
						</ButtonToolbar>
					</Form.Group>
				</div>
			</div>
		</Form>
	);
}
