import { useState, useEffect } from 'react';
import { SelectPicker, Loader } from 'rsuite';
import { compare } from '../../utils/helper.utils';
import firebaseService from '../../services/firebase.service';
import axios from 'axios';

export default function MessagingServicePicker(props) {
	const { defaultService, setService, name, onChange, defaultValue } = props;
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getData = async () => {
		setIsLoading(true);

		const token = await firebaseService.auth.currentUser.getIdToken(true);

		const resp = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/message_services`,
			{
				headers: {
					authorization: `Bearer ${token}`
				}
			}
		);

		setData(
			resp.data.map((d) => {
				return {
					label: d.name,
					value: d.externalId
				};
			})
		);
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			{isLoading ? (
				<Loader backdrop />
			) : (
				<SelectPicker
					data={data}
					name={name}
					onChange={onChange || setService}
					placeholder="Select Sender"
					defaultValue={defaultValue}
					sort={() => {
						return (a, b) => {
							return compare(a.label, b.label);
						};
					}}
				/>
			)}
		</div>
	);
}
