import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import firebaseService from '../../services/firebase.service';
import { DebounceInput } from 'react-debounce-input';

export default function HeaderComponent(props) {
	const { authorized, changeSearch } = props;
	const logUserOut = async () => {
		await firebaseService.auth.signOut();
	};

	const handleSearchChange = (value) => {
		changeSearch(value);
	};

	return (
		<header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
			<a
				className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6"
				href="#"
			>
				AlexellSMS
			</a>
			<button
				className="navbar-toggler position-absolute d-md-none collapsed"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#sidebarMenu"
				aria-controls="sidebarMenu"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>
			<DebounceInput
				className="form-control form-control-dark w-100 rounded-0 border-0"
				placeholder="Search"
				minLength={3}
				type="search"
				debounceTimeout={500}
				onChange={(event) => handleSearchChange(event.target.value)}
			/>

			<div className="navbar-nav">
				<div className="nav-item text-nowrap">
					{authorized ? (
						<a
							className="nav-link px-3"
							href="#"
							onClick={logUserOut}
						>
							Sign out
						</a>
					) : (
						<Link className="nav-link px-3" to="/signin">
							Sign In
						</Link>
					)}
				</div>
			</div>
		</header>
	);
}
