import { Panel } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import BroadcastForm from './broadcast_form.component';

export default function BroadcastNew(props) {
	const navigate = useNavigate();

	const formDone = (broadcastId) => {
		navigate('/broadcasts/' + broadcastId);
	};

	const formCancel = () => {
		navigate('/broadcasts');
	};

	return (
		<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2">New Broadcast</h1>
			</div>
			<div className="container">
				<Panel shaded>
					<BroadcastForm
						formDone={formDone}
						formCancel={formCancel}
					/>
				</Panel>
			</div>
		</main>
	);
}
