import { useState, useEffect } from 'react';

import axios from 'axios';
import firebaseService from '../../../../services/firebase.service';
import {
	BarChart,
	Bar,
	Cell,
	PieChart,
	Pie,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

import dayjs from 'dayjs';
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export default function ChartMessagesStatus(props) {
	const { dateRange = null, messagingService = null } = props;
	const [data, setData] = useState([]);

	const COLORS = ['#0088FE', '#CC0000', '#FFBB28', '#FF8042'];

	const getData = async () => {
		const token = await firebaseService.auth.currentUser.getIdToken(true);
		const params = {
			dateStart: dateRange?.startDate || null,
			dateFinish: dateRange?.endDate || null,
			messagingServiceSid: messagingService
		};
		console.log(params);

		const resp = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/stats/messages/daily`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params
			}
		);

		if (resp.status !== 200) {
			return {};
		}

		const data = resp.data.map((d) => {
			return {
				date: d.data.date,
				in: d.data.num_in,
				out: d.data.num_out,
				total: d.data.num_total,
				remove: d.data.num_remove
			};
		});

		const data_reduced = [
			...data
				.reduce((r, o) => {
					const key = 'summary';

					const item =
						r.get(key) ||
						Object.assign({}, o, {
							in: 0,
							out: 0,
							remove: 0,
							total: 0
						});

					item.in += o.in;
					item.out += o.out;
					item.total += o.total;
					item.remove += o.remove;

					return r.set(key, item);
				}, new Map())
				.values()
		];

		const chart_data = [
			{
				name: 'Messages Sent',
				value: data_reduced[0]?.out
			},
			{
				name: 'SMS Optouts',
				value: data_reduced[0]?.remove
			},
			{
				name: 'Failed Attempts',
				value:
					data_reduced[0]?.total -
					data_reduced[0]?.in -
					data_reduced[0]?.out
			}
		];

		setData(chart_data);
	};

	useEffect(() => {
		getData();
	}, [dateRange, messagingService]);

	return (
		<div className="card">
			<div className="card-body">
				<h5 className="card-title">
					Optout rate for this period was{' '}
					{data.length > 0
						? Math.round((data[1].value / data[0].value) * 100)
						: ''}
					%.
				</h5>

				<div className="my-4 w-100 chart-parent-small">
					<ResponsiveContainer width="100%">
						<PieChart width={800} height={400}>
							<Legend verticalAlign="bottom" height={36} />
							<Pie
								label
								data={data}
								dataKey="value"
								innerRadius={60}
								outerRadius={80}
								fill="#8884d8"
								paddingAngle={5}
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={COLORS[index % COLORS.length]}
									/>
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</div>
			</div>
		</div>
	);
}
