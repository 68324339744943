import './App.css';
import Dash from './components/dash/dash.component';
import { useStoreState, useStoreActions } from 'easy-peasy';
import firebaseService from './services/firebase.service';
import { useEffect, useState } from 'react';

function App() {
	const [loading, setLoading] = useState(true);
	const authorized = useStoreState((state) => state.authorized);
	const setAuthorized = useStoreActions((actions) => actions.setAuthorized);
	const setUnauthorized = useStoreActions(
		(actions) => actions.setUnauthorized
	);

	const authStateListener = () => {
		firebaseService.auth.onAuthStateChanged(async (user) => {
			if (!user) {
				setLoading(false);
				return setUnauthorized();
			}

			setLoading(false);
			return setAuthorized();
		});
	};

	useEffect(() => {
		authStateListener();
	}, [authStateListener]);

	return (
		<div className="App">
			{loading ? <p>Loading...</p> : <Dash authorized={authorized} />}
		</div>
	);
}

export default App;
