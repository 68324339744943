import { useState, useEffect } from 'react';
import firebaseService from '../../../services/firebase.service';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Panel,
	List,
	Loader,
	Placeholder,
	Button,
	ButtonToolbar
} from 'rsuite';
import BroadcastForm from './broadcast_form.component';
import axios from 'axios';
import dayjs from 'dayjs';
import ChartBroadcastStatus from '../reports/charts/broadcast_status';
import MessagesTable from '../messages/messages_table_component';
import EditIcon from '@rsuite/icons/Edit';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import ArowBackIcon from '@rsuite/icons/ArowBack';

export default function BroadcastView(props) {
	const { id } = useParams();
	const [data, setData] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const getData = async (id) => {
		setIsLoading(true);

		const token = await firebaseService.auth.currentUser.getIdToken(true);
		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/broadcasts/${id}`,
			{
				headers: {
					authorization: `Bearer ${token}`
				}
			}
		);

		if (response.status === 200) {
			setIsLoading(false);
			setData(response.data);
		}
	};

	const deletBtnClick = async () => {
		if (!window.confirm('Are you sure you wish to delete this broadcast?'))
			return;

		const token = await firebaseService.auth.currentUser.getIdToken(true);
		const response = await axios.delete(
			`${process.env.REACT_APP_SERVER}/api/broadcasts/${id}`,
			{
				headers: {
					authorization: `Bearer ${token}`
				}
			}
		);

		if (response.status === 200) {
			console.log(response);
			navigate('/broadcasts');
		}
	};

	const backBtnClick = () => {
		navigate('/broadcasts');
	};

	const editBtnClick = () => {
		setIsEditing(true);
	};

	const formDone = () => {
		setIsEditing(false);
		getData(id);
	};

	useEffect(() => {
		getData(id);
	}, []);

	return (
		<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2">Broadcast: {data.name}</h1>
				<div className="btn-toolbar mb-2 mb-md-0">
					<ButtonToolbar>
						<Button
							key="btn_back"
							appearance="subtle"
							onClick={backBtnClick}
							startIcon={<ArowBackIcon />}
						>
							Back
						</Button>
						<Button
							startIcon={<EditIcon />}
							key="btn_edit"
							color="blue"
							appearance="subtle"
							onClick={editBtnClick}
							disabled={
								isEditing ||
								['new', 'processing', 'scheduled'].indexOf(
									data.status
								) === -1
									? true
									: false
							}
						>
							Edit
						</Button>
						<Button
							key="btn_delete"
							color="red"
							appearance="subtle"
							onClick={deletBtnClick}
							disabled={
								['new', 'processing', 'scheduled'].indexOf(
									data.status
								) === -1
									? true
									: false
							}
							startIcon={<CloseOutlineIcon />}
						>
							Delete
						</Button>
					</ButtonToolbar>
				</div>
			</div>
			<div className="container">
				<Panel shaded>
					{isLoading ? (
						<Loader backdrop content="Hang on..." vertical />
					) : isEditing && data._id ? (
						<BroadcastForm
							broadcast={data}
							formDone={formDone}
							formCancel={formDone}
						/>
					) : (
						<div>
							<div className="row">
								<div className="col">
									<List size="md">
										<List.Item>
											<strong>List Name: </strong>
											{data.hubspotListName}
										</List.Item>
										<List.Item>
											<strong>Status: </strong>
											{data.status}
										</List.Item>
										<List.Item>
											<strong>Scheduled: </strong>
											{dayjs(data.sendAt).format('LLL')}
										</List.Item>
										<List.Item>
											<strong>Completed: </strong>
											{data.job?.completedAt}
										</List.Item>
										<List.Item>
											<strong>List Size: </strong>
											{data.job?.numAttempted}
										</List.Item>
									</List>
								</div>
								<div className="col">
									<Panel header="Message" bordered>
										<pre>{data.body}</pre>
									</Panel>
								</div>
							</div>

							{data.status !== 'new' ? (
								<div className="broadcast-charts">
									<Panel shaded>
										<ChartBroadcastStatus
											broadcastId={data._id}
											className="mt-2"
										/>
									</Panel>
									<Panel shaded className="mt-4">
										<MessagesTable
											filter={{ broadcastId: data._id }}
										/>
									</Panel>
								</div>
							) : (
								''
							)}
						</div>
					)}
				</Panel>
			</div>
		</main>
	);
}
