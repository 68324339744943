import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import firebaseService from '../../../services/firebase.service';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { Panel, Button, ButtonToolbar, Loader, Placeholder } from 'rsuite';
import dayjs from 'dayjs';
import PlusRoundIcon from '@rsuite/icons/PlusRound';

const LocalizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(LocalizedFormat);

export default function BroadcastPage(props) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [sort, setSort] = useState('createdAt');
	const [sortDirection, setSortDirection] = useState('desc');
	const { search } = props;
	const navigate = useNavigate();

	const columns = [
		{
			name: 'Broadcast Name',
			selector: (row) => row.name,
			sortable: true,
			grow: 2
		},
		{
			name: 'Hubspot List',
			selector: (row) => row.hubspotListName,
			sortable: true,
			grow: 2
		},
		{
			name: 'Send Time',
			selector: (row) => row.createdAt,
			sortable: true,
			sortField: 'sendAt',
			filterable: true,
			grow: 2,
			format: (row) => {
				return dayjs(row.sendAt).format('LLL');
			}
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			sortable: true
		},
		{
			name: 'List Size',
			selector: (row) => row.job?.numAttempted || 0,
			right: true,
			sortable: true
		},
		{
			name: 'Delivered',
			selector: (row) => row.job?.numDelivered || 0,
			right: true,
			sortable: true
		},
		{
			name: 'Optouts',
			selector: (row) => row.job?.numRemoved || 0,
			right: true,
			sortable: true
		}
	];

	const fetchBroadcasts = async (page) => {
		setLoading(true);
		const token = await firebaseService.auth.currentUser.getIdToken(true);

		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/broadcasts`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					page,
					perPage,
					sort,
					sortDirection,
					search
				}
			}
		);

		setData(response.data.data);
		setTotalRows(response.data.total);
		setLoading(false);
	};

	const handlePageChange = (page) => {
		fetchBroadcasts(page);
	};

	const handleSort = async (column, sortDirection) => {
		setLoading(true);
		const token = await firebaseService.auth.currentUser.getIdToken(true);

		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/broadcasts`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					page: 1,
					perPage,
					sort: column.sortField,
					sortDirection,
					search
				}
			}
		);

		setData(response.data.data);
		setLoading(false);
		setSort(column.sortField);
		setSortDirection(sortDirection);
	};

	const handleRowClick = (row, event) => {
		navigate('/broadcasts/' + row._id);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		const token = await firebaseService.auth.currentUser.getIdToken(true);

		const response = await axios.get(
			`${process.env.REACT_APP_SERVER}/api/broadcasts`,
			{
				headers: {
					authorization: `Bearer ${token}`
				},
				params: {
					page,
					perPage: newPerPage,
					sort,
					sortDirection,
					search
				}
			}
		);

		setData(response.data.data);
		setPerPage(newPerPage);
		setLoading(false);
	};

	const customStyles = {
		rows: {
			style: {
				cursor: 'pointer'
			}
		}
	};

	useEffect(() => {
		fetchBroadcasts(1); // fetch page 1 of users
	}, [search]);

	return (
		<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2">Broadcasts</h1>
				<div className="btn-toolbar mb-2 mb-md-0">
					<ButtonToolbar>
						<Button
							key="btn_back"
							appearance="subtle"
							color="blue"
							onClick={() => {
								navigate('/broadcasts/new');
							}}
							startIcon={<PlusRoundIcon />}
						>
							New Broadcast
						</Button>
					</ButtonToolbar>
				</div>
			</div>
			<div className="container">
				<Panel bordered>
					<DataTable
						title=""
						columns={columns}
						data={data}
						customStyles={customStyles}
						progressPending={loading}
						progressComponent={
							<div>
								<Placeholder.Paragraph rows={8} />
								<Loader
									backdrop
									content="Hang on..."
									vertical
								/>
							</div>
						}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						defaultSortFieldId={3}
						defaultSortAsc={false}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						onSort={handleSort}
						fixedHeader
						sortServer
						onRowClicked={handleRowClick}
						// expandableRows
						// expandableRowsComponent={ExpandedComponent}
						// conditionalRowStyles={conditionalRowStyles}
					/>
				</Panel>
			</div>
		</main>
	);
}
