import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyCVlqeM13wsNX39lhHCpCxn3fFz_w9f8TU',
	authDomain: 'alexell-sms.firebaseapp.com',
	projectId: 'alexell-sms',
	storageBucket: 'alexell-sms.appspot.com',
	messagingSenderId: '1019998019475',
	appId: '1:1019998019475:web:32fb7b27c6f7b3f0cb1266'
};

initializeApp(firebaseConfig);

const auth = getAuth();

export default {
	auth
};
