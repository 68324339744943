import { useState, useEffect } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import dayjs from 'dayjs';
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export default function DatePicker(props) {
	const { dateRange, setDateRange } = props;
	const [dateRangeVisible, setDateRangeVisible] = useState(false);
	const [dateRangeLabel, setDateRangeLabel] = useState(`
    ${dayjs(dateRange.startDate).format('LL')} - ${dayjs(
		dateRange.endDate
	).format('LL')}`);

	const handleSelect = (dates) => {
		setDateRange({
			startDate: dates[0],
			endDate: dates[1]
		});
	};

	const ranges = [
		{
			label: 'today',
			value: [
				dayjs().startOf('day').toDate(),
				dayjs().endOf('day').toDate()
			]
		},
		{
			label: 'yesterday',
			value: [
				dayjs().subtract(1, 'day').startOf('day').toDate(),
				dayjs().subtract(1, 'day').endOf('day').toDate()
			]
		},
		{
			label: 'Last Week',
			value: [
				dayjs().subtract(1, 'week').startOf('week').toDate(),
				dayjs().subtract(1, 'week').endOf('week').toDate()
			]
		},
		{
			label: 'Last Month',
			value: [
				dayjs().subtract(1, 'month').startOf('month').toDate(),
				dayjs().subtract(1, 'month').endOf('month').toDate()
			]
		},
		{
			label: 'This Week',
			value: [
				dayjs().startOf('week').toDate(),
				dayjs().endOf('week').toDate()
			]
		},
		{
			label: 'This Month',
			value: [
				dayjs().startOf('month').toDate(),
				dayjs().endOf('month').toDate()
			]
		}
	];

	return (
		<>
			<DateRangePicker
				defaultValue={[dateRange.startDate, dateRange.endDate]}
				isoWeek={true}
				placement="bottomEnd"
				onChange={handleSelect}
				ranges={ranges}
			/>
		</>
	);
}
