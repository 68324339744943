import MessagesTable from './messages_table_component';
import MessagingServicePicker from '../../messaging_service_picker/messaging_service_picker.component';
import DatePicker from '../../datepicker/datepicker.component';
import { useState, useEffect } from 'react';

import { Panel } from 'rsuite';

import dayjs from 'dayjs';
const LocalizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(LocalizedFormat);

export default function MessagesPage(props) {
	const { search = '', title = 'Messages' } = props;
	const [messagingServiceId, setMessagingServiceId] = useState();
	const [dateRange, setDateRange] = useState({
		startDate: dayjs().subtract(6, 'days').toDate(),
		endDate: dayjs().toDate()
	});

	useEffect(() => {}, [messagingServiceId, dateRange, search]);

	return (
		<main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2">Messages</h1>
				<div className="btn-toolbar mb-2 mb-md-0">
					<div className="btn-group me-2">
						<MessagingServicePicker
							defaultService={messagingServiceId}
							setService={setMessagingServiceId}
						/>
					</div>
					<DatePicker
						dateRange={dateRange}
						setDateRange={setDateRange}
					/>
				</div>
			</div>
			<div className="container">
				<Panel bordered>
					<MessagesTable
						search={search}
						title={''}
						messagingServiceSid={messagingServiceId}
						dateRange={dateRange}
					/>
				</Panel>
			</div>
		</main>
	);
}
